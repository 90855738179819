<template>
  <div>
    <div class="between ai-c mb-30">
      <h2>Этапы</h2>

      <VButton
        v-if="permissionCreateTemplateStageLine"
        text="Создать этапы"
        icon="create"
        size="sm"
        color="primary-sm"
        @click.native="goToPage('TemplateStageLineCreate')"
      />
    </div>

    <VSearch
      placeholder="Поиск по названию этапа"
      class="mb-30"
      @request="loadSearch"
    />

    <Spinner v-if="isLoading" />

    <div v-if="currentList.length && !isLoading" class="column gap-s">
      <CardFull
        v-for="item in currentList"
        :key="item.id"
        :state="item"
        :title="item.name"
        hasDetail
        @detail="goToDetail"
      />
    </div>

    <h2 v-if="!currentList.length && !isLoading">
      Ничего не найдено
    </h2>
  </div>
</template>

<script>
import CardFull from '@/components/ui/CardFull'
import VButton from '@/components/simple/button/VButton'
import VSearch from '@/components/Form/Search'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { goToPage } from '@/utils/router'

export default {
  name: 'TemplateStageLineList',
  components: {
    CardFull,
    VButton,
    VSearch
  },
  computed: {
    ...mapState('productions/template/stageLine', {
      isLoading: 'isLoading'
    }),
    ...mapGetters('productions/template/stageLine', {
      currentList: 'currentList'
    }),
    ...mapGetters('permission', [
      'permissionCreateTemplateStageLine'
    ])
  },
  destroyed () {
    this.RESET_STORE()
  },
  methods: {
    ...mapMutations('productions/template/stageLine', {
      RESET_STORE: 'RESET_STORE'
    }),
    ...mapActions('productions/template/stageLine', {
      loadSearch: 'loadSearch'
    }),
    goToPage,
    goToDetail (id) {
      goToPage('TemplateStageLineDetail', { id })
    }
  }
}
</script>
