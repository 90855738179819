<template>
  <div
    class="card-full"
    :class="{ 'has-detail': hasDetail }"
    v-on="hasDetail ? {click: detail} : null"
  >
    <h3>{{ title }}</h3>

    <div class="card-full__data">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardFull',
  props: {
    state: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    hasDetail: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    detail () {
      this.$emit('detail', this.state.id)
    },
    update () {
      this.$emit('update', this.state.id)
    },
    remove () {
      this.$emit('remove', this.state.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-full {
  width: 100%;
  background: $color__background--card;
  border: 1px solid $color__background--main;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 4px 4px 11px rgba(209, 217, 230, 0.2);
  transition: $transition;

  &:hover {
    cursor: pointer;
    transition: $transition;
    box-shadow: $color__card-box-shadow--hover;
  }

  @media screen and (max-width: $desktop-width-s) {
    padding: 20px 20px 30px;
  }

  &__buttons {
    @include df();
    margin-left: auto;

    &-item {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .card-full__data {
    @include df(center, flex-start, wrap);
  }
}
</style>
